import React, { useState } from 'react'
import {
    CButton,
    CCard,
    CCardBody,
    CCardGroup,
    CCol,
    CContainer,
    CForm,
    CInput,
    CInputGroup,
    CInputGroupPrepend,
    CInputGroupText,
    CRow,
    CAlert,
} from '@coreui/react'
import { CIcon } from '@coreui/icons-react'
import { useHistory } from 'react-router-dom';
import { getUser, isAuthenticated } from '../../util/securityUtil';
import { createNotification } from '../../api/notificationResource';

const RequestDeleteAccount = () => {
    const [username, setUsername] = useState('');
    const [requestSend, setRequestSend] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    let history = useHistory();

    const handleSubmit = async () => {
        if (isAuthenticated()) {
            const currentUser = getUser();
            if (currentUser.email === username) {
                const defaults = {
                    baslik: "HESAP SİLME İSTEĞİ",
                    icerik: "",
                    gonderiZamani: new Date(),
                    gonderici: currentUser,
                    aliciList: [{ id: -777 }],
                    bildirimEklentiList: [],
                };
                try {
                    await createNotification(defaults);
                    setRequestSend(true);
                    setErrorMessage('');
                } catch (e) {
                    console.error(e);
                    setErrorMessage('Hesap silme isteği gönderilirken bir hata oluştu.');
                }
            } else {
                setErrorMessage("Girdiğiniz mail adresi ile giriş yapmış olduğunuz mail adresi uyuşmamaktadır.");
            }
        } else {
            history.push(`/login`);
        }
    };

    return (
        <div className="c-app c-default-layout flex-row align-items-center">
            <CContainer>
                <CRow className="justify-content-center"></CRow>
                <CRow className="justify-content-center">
                    <CCol md="8">
                        <CCardGroup>
                            <CCard className="p-4">
                                <CCardBody>
                                    <div className="justify-content-center" style={{ textAlign: 'center', cursor: 'pointer' }} onClick={() => history.push("/")}>
                                        <img className="img-responsive" src={process.env.PUBLIC_URL + '/assets/img/biglogo.png'} alt="Logo" />
                                    </div>
                                    <br />
                                    <CForm>
                                        <h1>Hesap Silme İsteği</h1>
                                        <p className="text-muted">
                                            Silme işlemi sonrası Tedis uygulamasındaki verileriniz anonimleştirilerek saklanmaya devam eder.
                                            Hesabınızı silmeniz durumunda daha sonra tekrar hesap oluştursanız bile bu verilere erişemeyeceğinizi bilmelisiniz.
                                        </p>
                                        <CInputGroup className="mb-3">
                                            <CInputGroupPrepend>
                                                <CInputGroupText>
                                                    <CIcon name="cil-user" />
                                                </CInputGroupText>
                                            </CInputGroupPrepend>
                                            <CInput
                                                type="text"
                                                disabled={requestSend}
                                                placeholder="Mail Adresi"
                                                autoComplete="off"
                                                value={username}
                                                onChange={e => setUsername(e.target.value)}
                                                maxLength={255}
                                            />
                                        </CInputGroup>

                                        {errorMessage && <CAlert color="danger">{errorMessage}</CAlert>}

                                        {requestSend && (
                                            <CAlert color="success">
                                                Hesap silme isteği başarıyla gönderildi.
                                            </CAlert>
                                        )}

                                        {!requestSend && (
                                            <CRow>
                                                <CCol xs="6">
                                                    <CButton color="primary" className="px-4" onClick={handleSubmit} disabled={!username}>
                                                        Onayla
                                                    </CButton>
                                                </CCol>
                                            </CRow>
                                        )}
                                    </CForm>
                                </CCardBody>
                            </CCard>
                        </CCardGroup>
                    </CCol>
                </CRow>
            </CContainer>
        </div>
    );
};

export default RequestDeleteAccount;
