import React from 'react';
import { CCard, CCardBody, CCardHeader, CCol, CContainer, CRow } from '@coreui/react';

const PrivacyPolicy = () => {
  return (
    <CContainer>
      <CRow className="justify-content-center">
        <CCol md="8">
          <CCard>
            <CCardHeader>Gizlilik Politikası</CCardHeader>
            <CCardBody>
              <p><strong>Yürürlük Tarihi: 20.01.2025</strong></p>
              <p>
                Tedis (“biz,” “bizim,” veya “bize”) olarak, gizliliğinize değer veriyoruz ve kişisel verilerinizi korumayı taahhüt ediyoruz.
                Bu Gizlilik Politikası, mobil uygulamamızı (“Uygulama”) kullanırken sağladığınız bilgileri nasıl işlediğimizi açıklamaktadır.
              </p>
              <p><strong>Topladığımız Bilgiler</strong></p>
              <p>Sizden yalnızca aşağıdaki verileri topluyoruz:</p>
              <ul>
                <li>Kimlik bilgileri</li>
                <li>Eğitim bilgileri</li>
                <li>İlgi alanları</li>
                <li>Mesleki ve akademik bilgiler</li>
              </ul>
              <p><strong>Bilgilerinizi Nasıl Kullanıyoruz</strong></p>
              <p>Topladığımız bilgileri asistan eğitimi ve yönlendirmesini hızlandırmak amacıyla kullanıyoruz.</p>
              <p><strong>Şekillendirme Süreci</strong></p>
              <p>Topladığımız bilgileri yüksek güvenlikli sunucularımızda barındırıyoruz.</p>
              <p><strong>Bilgilerinizi Nasıl Paylaşıyoruz</strong></p>
              <p>Kişisel verilerinizi veya resimlerinizi herhangi bir üçüncü tarafla paylaşmıyoruz.</p>
              <p><strong>Veri Güvenliği</strong></p>
              <p>Sunucu ve sistemlerimizde düzenli bakım yaparak olası veri ihlallerini önlüyoruz.</p>
              <p><strong>Verilerinize Erişme veya Verilerinizi Güncelleme</strong></p>
              <p>Verilerinizi uygulamaya giriş yaparak değiştirebilir veya hesap silme isteğinde bulunabilirsiniz.</p>
              <p><strong>Çocukların Gizliliği</strong></p>
              <p>
                Uygulamamız 13 yaşın altındaki çocuklar tarafından kullanılmak üzere tasarlanmamıştır. Çocuklardan bilerek veri toplamıyoruz.
                Yanlışlıkla 13 yaşın altındaki bir çocuktan veri topladığımızın farkına varırsak, bu verileri derhal silmek için gerekli adımları atacağız.
              </p>
              <p><strong>Üçüncü Taraf Bağlantıları</strong></p>
              <p>
                Uygulamamız harici web sitelerine veya hizmetlere bağlantılar içerebilir. Bu üçüncü taraf sitelerinin içeriğinden veya gizlilik
                uygulamalarından sorumlu değiliz ve gizlilik politikalarını incelemenizi öneririz.
              </p>
              <p><strong>Bu Gizlilik Politikasındaki Değişiklikler</strong></p>
              <p>
                Bu Gizlilik Politikasını zaman zaman güncelleyebiliriz. Bunu yaptığımızda, güncellenmiş politikayı uygulamada yayınlayacağız
                ve en üstteki “Geçerlilik Tarihi”ni revize edeceğiz. Bilgilerinizi nasıl işlediğimiz hakkında bilgi sahibi olmak için bu
                politikayı periyodik olarak gözden geçirmenizi öneririz.
              </p>
              <p><strong>Bize ulaşın</strong></p>
              <p>info@heartsapiens.com</p>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </CContainer>
  );
};

export default PrivacyPolicy;
