import React, { useState, useEffect, useCallback } from "react";
import { CToast, CToastBody, CToastHeader, CToaster } from "@coreui/react";
import { subscribe, emmit } from "../../util/eventEmitter";

export const showSuccess = ({
  body = "Kayıt işlemi başarılı",
  title = "İşlem sonucu",
} = {}) => {
  emmit("TOAST", {
    type: "success",
    body,
    title,
  });
};

export const showError = ({
  body = "Beklenmedik hata",
  title = "Hata",
} = {}) => {
  emmit("TOAST", {
    type: "error",
    body,
    title,
  });
};

const Toaster = () => {
  const [toasts, setToasts] = useState([]);

  const addToast = useCallback(
    (payload) => {
      const { body, title, type } = payload;
      setToasts([
        ...toasts,
        {
          position: "top-right",
          autohide: 8000,
          closeButton: true,
          fade: true,
          body,
          title,
          type,
        },
      ]);
    },
    [toasts]
  );

  useEffect(() => {
    return subscribe("TOAST", addToast);
  }, [addToast]);

  window.addToast = addToast;

  const toasters = (() => {
    return toasts.reduce((toasters, toast) => {
      toasters[toast.position] = toasters[toast.position] || [];
      toasters[toast.position].push(toast);
      return toasters;
    }, {});
  })();

  return (
    <>
      {Object.keys(toasters).map((toasterKey) => (
        <CToaster position={toasterKey} key={"toaster" + toasterKey}>
          {toasters[toasterKey].map((toast, key) => {
            return (
              <CToast
                key={"toast" + key}
                show={true}
                autohide={toast.autohide}
                fade={toast.fade}
              >
                <CToastHeader
                  closeButton={toast.closeButton}
                  style={{
                    background: toast.type === "success" ? "green" : "red",
                    color: "white",
                  }}
                >
                  {toast.title}
                </CToastHeader>
                <CToastBody>{toast.body}</CToastBody>
              </CToast>
            );
          })}
        </CToaster>
      ))}
    </>
  );
};

export default Toaster;
