import React, { useState, useEffect } from 'react'
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CRow,
  CAlert,
} from '@coreui/react'
import { CIcon } from '@coreui/icons-react'
import { logout } from '../../util/securityUtil';
import { useHistory, useLocation } from 'react-router-dom';
import { resetPassword } from '../../api/accountResource';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ResetPassword = () => {

  useEffect(() => {
    logout();
  }, []);

  const [password, setPassword] = useState('');
  const [rePassword, setRePassword] = useState('');

  const [completed, setCompleted] = useState(false);
  const [passwordMissMatch, setPasswordMissMatch] = useState(false);

  let query = useQuery();

  const token = query.get("token");

  let history = useHistory();

  const handleSubmit = async () => {

    if (password !== rePassword) {
      setPasswordMissMatch(true);
      return;
    }
    setPasswordMissMatch(false);
    await resetPassword({ token, password });
    setCompleted(true);
  }

  return (
    <div className="c-app c-default-layout flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
        </CRow>
        <CRow className="justify-content-center">
          <CCol md="8">
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <div className="justify-content-center" style={{ textAlign: 'center', cursor: 'pointer' }} onClick={() => history.push("/")}>
                    <img className="img-responsive" src={process.env.PUBLIC_URL + '/assets/img/biglogo.png'} alt="Logo" />
                  </div>
                  <br />
                  <CForm>
                    <h1>Şifreni Sıfırla</h1>
                    <p className="text-muted">Yeni Şifre bilginizi giriniz.</p>
                    <CInputGroup className="mb-4">
                      <CInputGroupPrepend>
                        <CInputGroupText>
                          <CIcon name="cil-lock-locked" />
                        </CInputGroupText>
                      </CInputGroupPrepend>
                      <CInput type="password" placeholder="Şifre" autoComplete="current-password" value={password} onChange={e => setPassword(e.target.value)} />
                    </CInputGroup>
                    <CInputGroup className="mb-4">
                      <CInputGroupPrepend>
                        <CInputGroupText>
                          <CIcon name="cil-lock-locked" />
                        </CInputGroupText>
                      </CInputGroupPrepend>
                      <CInput type="password" placeholder="Şifre (Tekrar)" autoComplete="current-password" value={rePassword} onChange={e => setRePassword(e.target.value)} />
                    </CInputGroup>
                    {
                      passwordMissMatch &&
                      <CAlert color="danger">
                        Şifreler uyuşmuyor.
                      </CAlert>
                    }
                    {!completed && <CRow>
                      <CCol xs="6">
                        <CButton color="primary" className="px-4" onClick={handleSubmit} disabled={!password}>Onayla</CButton>
                      </CCol>
                    </CRow>
                    }
                    {completed &&
                      <CAlert color="success">
                        <CButton color="link" className="px-0" onClick={() => history.push("/login")}>Şifreniz yenilendi. Buradan oturum açabilirsiniz.</CButton>
                      </CAlert>
                    }
                  </CForm>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}

export default ResetPassword;
