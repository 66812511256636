import restClient from './config/restConfig';

export const getAccount = async () => {
    const response = await restClient.get('/account');
    if (response.data) {
        response.data.password = "";
    }
    return response;
}


export const updateAccount = async (data) => {
    data.authorities = null;
    const response = await restClient.put(`/account`, data);
    response.data.password = null;
    return response;
};


export const resetPasswordInit = async (username) => {
    await restClient.post(`/account/reset-password-init`, { username })
}

export const resetPassword = async (data) => {
    const response = await restClient.post(`/account/reset-password`, data);
    return response.data;

}