import React, { useEffect, useState } from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import "./scss/style.scss";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import { loadAccount } from "./util/securityUtil";
import ForgotPassword from "./views/Login/ForgotPassword";
import ResetPassword from "./views/Login/ResetPassword";
import moment from "moment";
import RequestDeleteAccount from "./containers/DeleteAccountContainer/ConfirmDeleteAccount";
import PrivacyPolicy from "./views/PrivacyPolicy/PrivacyPolicy";

moment.utc().startOf("day").subtract(8, "hours");

const fallback = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

const Loading = () => {
  return fallback;
};


const DefaultLayout = React.lazy(() => import("./containers/DefaultLayout"));
const Login = React.lazy(() => import("./views/Login/Login"));
const Page401 = React.lazy(() => import("./views/Page401/401"));

const App = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadAccount().then(() => setLoading(false));
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <HashRouter>
      <React.Suspense fallback={fallback}>
        <Switch>
          <Route
            exact
            path="/login"
            name="Login Page"
            render={(props) => <Login {...props} />}
          />
          <Route
            exact
            path="/forgot-password"
            name="Forgot Password Page"
            render={(props) => <ForgotPassword {...props} />}
          />
          <Route
            exact
            path="/reset-password"
            name="Reset Password Page"
            render={(props) => <ResetPassword {...props} />}
          />
          <Route path="/401" component={Page401} />
          <Route
            exact
            path="/request-delete-account"
            name="Request Delete Account"
            render={(props) => <RequestDeleteAccount {...props} />}
          />
          <Route
            exact
            path="/privacy-policy"
            name="Gizlilik Politikası"
            render={(props) => <PrivacyPolicy {...props} />}
          />
          <PrivateRoute
            path="/"
            name="Home"
            render={(props) => <DefaultLayout {...props} />}
          />
        </Switch>
      </React.Suspense>
    </HashRouter>
  );
};

export default App;
