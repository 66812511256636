export const DEVICE_CHANGE_CHANNEL = "DEVICE_CHANGE_CHANNEL";

export const EVENT_CHANGE_CHANNEL = "EVENT_CHANGE_CHANNEL";

export const ESTABLISHMENTS_CHANGE_CHANNEL = "ESTABLISHMENTS_CHANGE_CHANNEL";

export const ESTABLISHMENT_WORKING_PLAN_CHANGE_CHANNEL = "ESTABLISHMENT_WORKING_PLAN_CHANGE_CHANNEL";

export const OBSERVATION_CHANGE_CHANNEL = "OBSERVATION_CHANGE_CHANNEL";

export const TREATMENT_CHANGE_CHANNEL = "TREATMENT_CHANGE_CHANNEL";

export const TREATMENT_RESULT_CHANGE_CHANNEL =
  "TREATMENT_RESULT_CHANGE_CHANNEL";

export const PATIENTS_CHANGE_CHANNEL = "PATIENTS_CHANGE_CHANNEL";

export const USERS_CHANGE_CHANNEL = "USERS_CHANGE_CHANNEL";

export const COMPLICATION_CHANGE_CHANNEL = "COMPLICATION_CHANGE_CHANNEL";

export const COMPLICATION_TREATMENT_CHANGE_CHANNEL =
  "COMPLICATION_TREATMENT_CHANGE_CHANNEL";

export const PATIENT_DEVICE_CHANGE_CHANNEL = "PATIENT_DEVICE_CHANGE_CHANNEL";

export const OBSERVATION_VALUE_CHANGE_CHANNEL =
  "OBSERVATION_VALUE_CHANGE_CHANNEL";

export const PATIENT_MESSAGE_CHANGE_CHANNEL = "PATIENT_MESSAGE_CHANGE_CHANNEL";

export const LOGS_QUERY_CHANGE_CHANNEL = "LOGS_QUERY_CHANGE_CHANNEL";

export const NOTIFICATION_CHANGE_CHANNEL = "NOTIFICATION_CHANGE_CHANNEL";

export const KUMADIN_CHANGE_CHANNEL = "KUMADIN_CHANGE_CHANNEL";

export const PARMANENT_MEDICINE_CHANGE_CHANNEL =
  "PARMANENT_MEDICINE_CHANGE_CHANNEL";

export const PHYSIOTHREAPTH_CHANGE_CHANNEL = "PHYSIOTHREAPTH_CHANGE_CHANNEL";

export const DIET_CHANGE_CHANNEL = "DIET_CHANGE_CHANNEL";

export const PSYCHOTHREAPTY_CHANGE_CHANNEL = "PSYCHOTHREAPTY_CHANGE_CHANNEL";

export const PATIENT_OBSERVATION_PLAN_CHANGE_CHANNEL =
  "PATIENT_OBSERVATION_PLAN_CHANGE_CHANNEL";

export const ACTIVE_FORM_CHANGE_CHANNEL = "ACTIVE_FORM_CHANGE_CHANNEL";
