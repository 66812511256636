import React, { useState, useEffect } from 'react'
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CRow,
  CAlert,
} from '@coreui/react'
import { CIcon } from '@coreui/icons-react'
import { logout } from '../../util/securityUtil';
import { useHistory } from 'react-router-dom';
import { resetPasswordInit } from '../../api/accountResource';

const ForgotPassword = () => {

  useEffect(() => {
    logout();
  }, []);

  const [username, setUsername] = useState('');
  const [passwordSend, setPasswordSend] = useState(false)

  let history = useHistory();

  const handleSubmit = async () => {
    await resetPasswordInit(username);
    setPasswordSend(true);
  }

  return (
    <div className="c-app c-default-layout flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
        </CRow>
        <CRow className="justify-content-center">
          <CCol md="8">
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <div className="justify-content-center" style={{ textAlign: 'center', cursor: 'pointer' }} onClick={() => history.push("/")}>
                    <img className="img-responsive" src={process.env.PUBLIC_URL + '/assets/img/biglogo.png'} alt="Logo" />
                  </div>
                  <br />
                  <CForm>
                    <h1>Şifremi Unuttum</h1>
                    <p className="text-muted">Kullanıcı adı bilgisi girdiğinizde şifre sıfırlama linki eposta ile gönderilecektir.</p>
                    <CInputGroup className="mb-3">
                      <CInputGroupPrepend>
                        <CInputGroupText>
                          <CIcon name="cil-user" />
                        </CInputGroupText>
                      </CInputGroupPrepend>
                      <CInput type="text" disabled={passwordSend} placeholder="Kullanıcı Adı" autoComplete="off" value={username} onChange={e => setUsername(e.target.value)} />
                    </CInputGroup>
                    {
                      passwordSend &&
                      <CAlert color="success">
                        Eposta adresine şifre sıfırlama linki gönderildi.
                      </CAlert>
                    }
                    {!passwordSend && <CRow>
                      <CCol xs="6">
                        <CButton color="primary" className="px-4" onClick={handleSubmit} disabled={!username}>Onayla</CButton>
                      </CCol>
                      <CCol xs="6" className="text-right">
                        <CButton color="link" className="px-0" onClick={() => history.push("/login")}>Oturum aç</CButton>
                      </CCol>
                    </CRow>}
                  </CForm>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}

export default ForgotPassword;
