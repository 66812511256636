import restClient from "./config/restConfig";
import { toUrlParemters } from "./config/restUtil";
import { emmit } from "../util/eventEmitter";
import { NOTIFICATION_CHANGE_CHANNEL } from "./config/channels";

export const getNotifications = (criteria) => {
  criteria.username = criteria.gonderici;
  criteria.hasta_ad = criteria.aliciList;
  criteria.ve_baglac = false;
  criteria.hasta_soyad = criteria.aliciList;
  let urlParameters = toUrlParemters(criteria);

  return restClient.get(`/bildirim/query?${urlParameters}`);
};

export const getNotification = async (id) => {
  return restClient.get(`/bildirim/${id}`);
};

export const createNotification = async (data) => {
  const newData = await restClient.post(`/bildirim`, data);
  emmit(NOTIFICATION_CHANGE_CHANNEL);
  return newData;
};

export const updateNotification = async (id, data) => {
  const newData = await restClient.put(`/bildirim/${id}`, data);
  emmit(NOTIFICATION_CHANGE_CHANNEL);
  return newData;
};

export const deleteNotification = async (id) => {
  await restClient.delete(`/bildirim/${id}`);
  emmit(NOTIFICATION_CHANGE_CHANNEL);
};

export const downloadNotification = async (filename, id) => {

  const criteria = { uuid: id };

  let urlParameters = toUrlParemters(criteria);
  restClient.get(`/bildirimeklenti/content?${urlParameters}`, { responseType: 'arraybuffer' }).then(response => {

    const contentType = response.headers['content-type'];

    // ArrayBuffer'ı Blob'a çevirme
    const blob = new Blob([response.data], { type: contentType });

    // Blob'dan URL oluşturma
    const url = URL.createObjectURL(blob);

    // Dosyayı tarayıcıda açma
    window.open(url, '_blank', 'height=600,width=600');

    // URL'yi serbest bırakma ve linki kaldırma
    URL.revokeObjectURL(url);
    
  })
    .catch(error => {
      console.error('Error fetching the file:', error);
    });

  //window.open(`http://localhost:8000/rest/v1/bildirimeklenti/content?uuid=${id}`,filename,'height=600,width=600');

  /* ORJİNAL
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  */

}
